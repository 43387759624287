<script setup lang="ts">
const props = defineProps({
    title: {
        type: String,
        default: 'Page',
    },
    image: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        required: false,
        default: '',
    },
    showLink: {
        type: Boolean,
        default: false,
    },
});

const { title, image, showLink } = props

useHead({
    title: 'STORM - ' + title,
})


</script>
<template>
    <NuxtImg :src="image" alt="Frontpage" class="relative h-[450px] object-cover min-w-full"></NuxtImg>
    <div v-if="showLink" class="absolute justify-end text-orange-400 text-[10pt] top-[320px] -left-[60px] -rotate-90">
        <NuxtLink to="/contact">59˚25´1610´´N, 10˚26´4170´´E</NuxtLink>
    </div>
</template>